<template>
  <div>
    <h2>{{ $t('views.graduatediscounts.detail.title') }}</h2>

    <entity-detail-table :entityKey="eParent.key" :entityId="eParent.id" @control:input="onInput" @control:change="onChange" @entity:save="onSave" />
  </div>
</template>

<script>
import EntityDetailTable from '@/components/entity/DetailTable'
import MixinEntityBase from '@/components/mixins/EntityBase'

export default {
  name: 'GraduateDiscounts.Detail',
  mixins: [MixinEntityBase],
  components: {
    EntityDetailTable
  },
  methods: {
    onInput (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onChange (controlValue) {
      /* do something with controlValue = { [propKey]: value } */
    },
    onSave (entity) {
      this.$store.dispatch(`${this.eParent.key}/updateEntity`, entity)
    }
  }
}
</script>

<style lang="scss"></style>
